<template>
  <a-drawer
      :zIndex="1000"
      :title="getTitle"
      :width="'650px'"
      @close="onClose"
      :visible="visible"
      :drawerStyle="{height: 'calc(100% - 108px)'}"
    >
      <bh-loading :show="loading" />
      <bh-loading :show="loadingPlace" />

      <a-form :form="form"  >
        <template v-if="newDialog && newDialog.includes('marker')">

          <template v-if="newDialog && newDialog.includes('---google')">
            <!-- <a-row :gutter="16" v-if="!placeChosen"> -->
			      <a-row :gutter="16">
              <a-col :span="24" >
                <a-form-item label="Search places from Google">
                  <a-auto-complete
                    @select="selected"
                    :value="placeSelected"
                    @input.native="$event => googleQuery = $event.target.value "
                    class="certain-category-search"
                    dropdownClassName="certain-category-search-dropdown"
                    :dropdownMatchSelectWidth="false"
                    :dropdownStyle="{width: '300px'}"
                    size="large"
                    style="width: 100%"
                    placeholder="Enter address or location name here"
                    optionLabelProp="value"
                  >
                    <template slot="dataSource">
                      <a-select-option v-for="opt in dataSource" :key="opt.place_id" :value="`${opt.place_id}`">
                          {{opt.main_text}}<br/>
                          <span class="certain-search-item-count">{{opt.secondary_text}}</span>
                        </a-select-option>

                    </template>
                    <a-input>
                      <a-icon slot="suffix" type="search" class="certain-category-icon" />
                    </a-input>
                  </a-auto-complete>
                </a-form-item>
              </a-col>
			        <a-col :span="24">
                  <div id="mini-map" style="width:100%; height:500px;"></div>
            	</a-col>
            </a-row>
            <!-- <div v-else-if="processingGoogle">
                Loading.....
            </div> -->

            <!-- <template v-else-if="location && location.name"> -->
			      <template>

              <a-row class="mt-5" :gutter="16">
                <a-col :span="12">
                  <a-form-model :model="marker" ref="marker">
                    <a-form-model-item required prop="categoryId" :rules="req('Please select a Category')" label="Choose a Category">
                      <a-select v-model="marker.categoryId">
                        <template v-for="cat in categories">
                          <a-select-option v-if="cat.id !== '__pinnedMarkers' && cat.id !== '__pinnedMarkers2'" :value="cat.id" :key="cat.id">
                            <template>{{cat.name}}</template>
                          </a-select-option>
                        </template>
                      </a-select>
                    </a-form-model-item>
                  </a-form-model>
                </a-col>
              </a-row>

              <a-form-model :model="location" ref="location">
                <a-row :gutter="16">
                  <a-col :span="12">
                    <a-form-model-item :rules="req('Please enter the Marker Name')" required prop="name" label="Name">
                      <a-input v-model="location.name" placeholder="Enter your marker name" >
                      </a-input>
                    </a-form-model-item>
                  </a-col>
                  <a-col :span="12">
                    <strong class="block">Rating</strong>
                    {{location.rating}}&nbsp;&nbsp;
                    <a-rate :value="location.rating" allow-half :disabled="true" />
                  </a-col>
                </a-row>
                <a-row :gutter="16">
                  <a-col :span="12">
                    <a-form-model-item prop="address" label="Address">
                      <a-input v-model="location.formatted_address" placeholder="Address" >
                      </a-input>
                    </a-form-model-item>
                  </a-col>
                </a-row>
              </a-form-model>

              <a-divider />
                <a-row>
                    <!-- <a-col :span="8">
                        <img :src="location.image" style="max-width:100%"/>
                    </a-col> -->
                    <a-col :span="12">
                      <strong class="block">Image</strong>
                      <a-form-item>
                        <marker-image-selector :img="location.image" @callback="updateLocationImage"></marker-image-selector>
                      </a-form-item>
                    </a-col>

                </a-row>

              <a-divider />
              <div class="source">
                  <small>Sourced from Google &copy; </small>
              </div>
              <!-- <a-divider />
              <div class="source">
                  <a-form-item label="Choose a Category">
                    <a-select v-model="marker.categoryId">
                      <a-select-option :value="cat.id" :key="cat.id" v-for="cat in categories">{{cat.name}}</a-select-option>
                    </a-select>
                  </a-form-item>
              </div> -->
            </template>
          </template>



          <template v-else>
              <a-row :gutter="16">

                <a-col :span="24">
                  <a-form-item label="Search places from Google">
                    <a-auto-complete
                      @select="selectedManual"
                      :value="placeSelected"
                      @input.native="$event => googleQuery = $event.target.value "
                      class="certain-category-search"
                      dropdownClassName="certain-category-search-dropdown"
                      :dropdownMatchSelectWidth="false"
                      :dropdownStyle="{width: '300px'}"
                      size="large"
                      style="width: 100%"
                      placeholder="Enter address or location name here"
                      optionLabelProp="value"
                    >
                    <template slot="dataSource">
                      <a-select-option v-for="opt in dataSource" :key="opt.place_id" :value="`${opt.place_id}`">
                        {{opt.main_text}}<br/>
                        <span class="certain-search-item-count">{{opt.secondary_text}}</span>
                      </a-select-option>
                    </template>
                      <a-input>
                        <a-icon slot="suffix" type="search" class="certain-category-icon" />
                      </a-input>
                    </a-auto-complete>
                  </a-form-item>
                </a-col>
                <!--<a-col :span="6">
                  <a-form-item label="a" class="hidden-label">
                      <a-button type="primary" @click.native="putMarker(marker.geometry.location.lat,marker.geometry.location.lng)">Update Marker</a-button>
                  </a-form-item>
                </a-col>-->
                <a-col :span="24">
                  <div id="mini-map" style="width:100%; height:500px;"></div>
                </a-col>
              </a-row>

			        <a-row :gutter="16">
                <a-col :span="24">
                  <a-form-model :model="marker" ref="marker">
                    <a-form-model-item :rules="req('Please enter the Marker Name')" required prop="name" label="Marker Name" class="mt-5">
                      <a-input v-model="marker.name" placeholder="Enter your marker name" >
                      </a-input>
                    </a-form-model-item>
                  </a-form-model>
                </a-col>
              </a-row>

              <a-row :gutter="16">
                <a-col :span="12">
                  <a-form-item label="Image">
                    <marker-image-selector :img="marker.image" @callback="updateMarkerImage"></marker-image-selector>
                  </a-form-item>
                </a-col>
                <a-col :span="12">
                  <a-form-item>
                    <div @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerIcon,type:'images'})" class="edit-current-marker" style="top: 30px;"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></div>
                    <div class="dF aC" style="line-height: 2;">
                      Marker Icon
                      <a-tooltip class="ml-1" overlayClassName="change-tooltip-color">
                          <template slot="title">
                              For optimal resolution size to 300px x 300px. Save your file as either a PNG or JPEG no larger than 200KB
                          </template>
                          <a-icon type="question-circle" style="line-height: 25px; color: var(--orange);" />
                      </a-tooltip>
                    </div>
                    <marker-selector @callback="updateMarkerIcon">
                      <div class="image-holder-box">
                        <span class="content">
                        <div class="inside" :style="{ 'background-image': 'url(' + marker.icon + ')' }" />
                        </span>
                      </div>
                    </marker-selector>
                    <!-- <a-button type="primary" class="mt-3" @click="$store.commit('MEDIA_SELECT',{callback:selectedMarkerIcon,type:'images'})">Select from File Manager </a-button> -->
                  </a-form-item>
                </a-col>
              </a-row>


          </template>

        </template>

        <!--  ELSE Category Dialog-->
        <template v-else-if="newDialog">
			<template v-if="newDialog && newDialog.includes('google')">
				<p>Please choose a category to continue</p>
				<a-select size="large" style="width: 300px" v-model="chosenGoogleCat" placeholder="Choose a Category" @change="googleCatChange">
					<a-select-option v-for="(cat,catI) in amenityCats" :value="cat.label" :key="(catI + 9).toString(36) + cat.label">
					{{cat.label}}
					</a-select-option>
				</a-select>
				<a-divider orientation="left" class="pt-5">Options</a-divider>
				<a-row :gutter="16">
					<a-col :span="12">
						<a-form-item label="Category Icon">
							<marker-selector @callback="updateCatIcon">
							<div class="image-holder-box">
								<span class="content">
								<div class="inside" :style="{ 'background-image': 'url(' + category.icon + ')' }" />
								</span>
							</div>
							</marker-selector>

							<a-button type="primary" class="mt-3" @click="$store.commit('MEDIA_SELECT',{callback:selectedImg,type:'images'})">Select from File Manager </a-button>

							<!-- <image-box-selector v-model="category.icon" type="images"/> -->
							<!-- <div  class="dF aE"> -->

							<!-- <a-card hoverable style="width: 100px" >
								<img
								alt="example"
								style="width:100;height:100px;object-fit:contain"
								:src="category.icon"
								slot="cover"
								/>

							</a-card> -->

							<!-- <div class="ml-2">
								<div class="">
								<a-button size="small" type="primary" @click="selectIcon">Choose from File Manager</a-button>
								</div>
								<div class="mt-2">
								<a-button size="small" @click="iconLibrary" type="primary">Choose from Icon Library</a-button>
								</div>
							</div> -->
							<!-- </div> -->

						</a-form-item>
					</a-col>
				</a-row>
				<template v-if="chosenGoogleCat">
					<a-form-item label="Label" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol">
						<a-input v-model="chosenGCat.rename"  />
					</a-form-item>

					<!-- Select Choice -->

					<a-form-item label="Types to Show" :label-col="formLayout.labelCol" :wrapper-col="formLayout.wrapperCol">
						<a-checkbox-group :options="chosenGCat.options" v-model="chosenGCat.value" />
					</a-form-item>

					<a-form-item label="Min. Rating" :label-col="formLayout.labelCol" v-if="chosenGCat.configure && chosenGCat.configure.hasOwnProperty('rating__min')" :wrapper-col="formLayout.wrapperCol">
						<a-rate v-model="chosenGCat.configure.rating__min" allow-half />
					</a-form-item>
					<a-form-item label="Price" :label-col="formLayout.labelCol"   v-if="chosenGCat.configure && chosenGCat.configure.hasOwnProperty('price_level__min')" :wrapper-col="formLayout.wrapperCol">
						<a-rate v-model="chosenGCat.configure.price_level__min" allow-half :count="3">
							<a-icon slot="character" type="dollar" />
						</a-rate>
					</a-form-item>
				</template>
			</template>





			<template v-else>
				<a-form-model :model="category" ref="category">
					<a-row :gutter="16">
						<a-col :span="12">
						<a-form-model-item label="Category Title" required prop="label" :rules="req('Please enter the Category Title')">
							<a-input
								v-model="category.label"
								placeholder="Enter category name"
								required
							>
							</a-input>
						</a-form-model-item>
						</a-col>
						<!-- <a-col :span="12">
						<a-form-item label="Pinned Category?">
							<a-switch defaultChecked v-model="category.isStatic" />
						</a-form-item>
						</a-col> -->
					</a-row>

					<a-row :gutter="16">
						<a-col :span="24">
							<a-form-model-item label="Description" prop="description">
								<a-textarea
								:rows="4"
								placeholder="Please enter a description about this Category. This could help in publishing your amenities in different ways."
								v-model="category.description"
								required
								/>
							</a-form-model-item>
						</a-col>
					</a-row>
				</a-form-model>
					<a-row :gutter="16">
						<a-col :span="12">
							<a-form-item>
								<div @click="$store.commit('MEDIA_SELECT',{callback:selectedImg,type:'images'})" style="margin-top: 2em;" class="edit-current-marker"><i aria-label="icon: edit" class="anticon anticon-edit"><svg viewBox="64 64 896 896" data-icon="edit" width="1em" height="1em" fill="currentColor" aria-hidden="true" focusable="false" class=""><path d="M257.7 752c2 0 4-.2 6-.5L431.9 722c2-.4 3.9-1.3 5.3-2.8l423.9-423.9a9.96 9.96 0 0 0 0-14.1L694.9 114.9c-1.9-1.9-4.4-2.9-7.1-2.9s-5.2 1-7.1 2.9L256.8 538.8c-1.5 1.5-2.4 3.3-2.8 5.3l-29.5 168.2a33.5 33.5 0 0 0 9.4 29.8c6.6 6.4 14.9 9.9 23.8 9.9zm67.4-174.4L687.8 215l73.3 73.3-362.7 362.6-88.9 15.7 15.6-89zM880 836H144c-17.7 0-32 14.3-32 32v36c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-36c0-17.7-14.3-32-32-32z"></path></svg></i></div>
								<div class="dF aC" style="line-height: 2;">
                  Marker Icon
                  <a-tooltip class="ml-1" overlayClassName="change-tooltip-color">
                      <template slot="title">
                          For optimal resolution size to 300px x 300px. Save your file as either a PNG or JPEG no larger than 200KB
                      </template>
                      <a-icon type="question-circle" style="line-height: 25px; color: var(--orange);" />
                  </a-tooltip>
                </div>
                <marker-selector @callback="updateCatIcon">
								<div class="image-holder-box">
									<span class="content">
									<div class="inside" :style="{ 'background-image': 'url(' + category.icon + ')' }" />
									</span>
								</div>
								</marker-selector>

								<!-- <a-button type="primary" class="mt-3" @click="$store.commit('MEDIA_SELECT',{callback:selectedImg,type:'images'})">Select from File Manager </a-button> -->

								<!-- <image-box-selector v-model="category.icon" type="images"/> -->
								<!-- <div  class="dF aE">
								<a-card hoverable style="width: 100px" >
									<img
									alt="example"
									style="width:100;height:100px;object-fit:contain"
									:src="category.icon"
									slot="cover"
									/>

								</a-card>

								<div class="ml-2">
									<div class="">
									<a-button size="small" type="primary" @click="selectIcon">Choose from File Manager</a-button>
									</div>
									<div class="mt-2">
									<a-button size="small" @click="iconLibrary" type="primary">Choose from Icon Library</a-button>
									</div>
								</div>
								</div> -->

							</a-form-item>
						</a-col>
					</a-row>

					<a-row :gutter="16">
						<a-col :span="12">
							<h5>Types</h5>
							<p>Sourced from Google</p>
						</a-col>
					</a-row>
					<a-checkbox-group @change="selectedCategories">
						<a-row type="flex">
							<a-col class="mb-4" :span="8" v-for="(cat,catI) in allCats" :key="cat+catI">
                <div style="font-weight: 600;">{{catI}}</div>
                <template v-for="(aCat,aCatI) in cat">
                  <a-checkbox class="w-full" style="margin-left: 0;" :value="aCat" :key="aCat+aCatI">
                    {{aCat[0].toUpperCase() + aCat.split('_').join(' ').slice(1)}}
                  </a-checkbox>
                </template>
							</a-col>
						</a-row>
					</a-checkbox-group>
			</template>
        </template>
      </a-form>
	  <form-submit @submit="submitNew" @cancel="onClose">
	  </form-submit>
    </a-drawer>
</template>

<script>
  import {markerIcon} from 'bh-mod'
  import bhLoading from 'bh-mod/components/common/Loading'
  import * as maptalks from 'maptalks';
  import ImageBoxSelector from 'bh-mod/components/common/ImageBoxSelector'
  import MarkerSelector from '@/components/amenities/MarkerSelector'
  import MarkerImageSelector from '@/components/amenities/MarkerImageSelector'
  import FormSubmit from '@/components/amenities/FormSubmit'
  export default {
    components:{bhLoading,ImageBoxSelector,MarkerSelector,MarkerImageSelector,FormSubmit},
    data() {
      return {
        point:null,
        map:null,
        vectorLayer:null,
        chosenGCat:{},
        formLayout:{
          labelCol: {span:4},
          wrapperCol: { span: 14 },
        },
        chosenGoogleCat:null,
        loading:false,
        loadingPlace:false,
        form: this.$form.createForm(this),
        googleQuery:'',
        dataSource:[],
        processingGoogle:false,
        placeSelected:'',
        placeChosen:false,
        marker:{
          name:'',
		      categoryId:null,
          geometry:{
            location:{
              lat:null,
              lng:null
            }
		      },
          icon:markerIcon,
          image:''
        },
        category:{
          label:'',
          icon:markerIcon,
          description:'',
		    },
		    chosenCategories:[
          {
            label:'',
          },
        ],
        location:{
          name:'',
          image:'',
          formatted_address:'',
          geometry:{
            location:{
              lat:null,
              lng:null
            }
          }
        },
        pStyle: {
          fontSize: '16px',
          color: 'rgba(0,0,0,0.85)',
          lineHeight: '24px',
          display: 'block',
          marginBottom: '16px',
		    },
        allCats:{
          "Commute":[
            'airport',
            'bus_station',
            'light_rail_station',
            'subway_station',
            'taxi_stand',
            'train_station',
            'transit_station'
          ],
          "Food & Drink":[
            'bakery',
            'bar',
            'cafe',
            'liquor_store',
            'meal_delivery',
            'meal_takeaway',
            'restaurant',
            'supermarket'
          ],
          "Health & Personal Care":[
            'beauty_salon',
            'dentist',
            'doctor',
            'gym',
            'hair_care',
            'hospital',
            'pharmacy',
            'physiotherapist',
            'spa',
            'veterinary_care'
          ],
          "Places of Worship":[
            'cemetery',
            'church',
            'hindu_temple',
            'mosque',
            'synagogue'
          ],
          "Public Services":[
            'city_hall',
            'courthouse',
            'embassy',
            'fire_station',
            'library',
            'local_government_office',
            'police',
            'post_office'
          ],
          "Schools":[
            'primary_school',
            'school',
            'secondary_school',
            'university'
          ],
          "Recreation & Entertainment":[
            'amusement_park',
            'aquarium',
            'art_gallery',
            'bowling_alley',
            'campground',
            'casino',
            'movie_rental',
            'movie_theater',
            'museum',
            'night_club',
            'park',
            'rv_park',
            'stadium',
            'tourist_attraction',
            'zoo'
          ],
          "Services":[
            'accounting',
            'atm',
            'car_rental',
            'car_repair',
            'car_wash',
            'electrician',
            'funeral_home',
            'gas_station',
            'insurance_agency',
            'laundry',
            'lawyer',
            'locksmith',
            'lodging',
            'moving_company',
            'painter',
            'parking',
            'plumber',
            'real_estate_agency',
            'roofing_contractor',
            'storage',
            'travel_agency',
          ],
          "Shopping":[
            'bicycle_store',
            'book_store',
            'car_dealer',
            'clothing_store',
            'convenience_store',
            'department_store',
            'drugstore',
            'electronics_store',
            'florist',
            'furniture_store',
            'hardware_store',
            'home_goods_store',
            'jewelry_store',
            'pet_store',
            'shoe_store',
            'shopping_mall',
            'store'
          ],

        },
        showOptions: false
      };
    },
    watch:{
      googleQuery(val){
        if (val.includes('plid___')) {
          console.log('HEY TRANFSFORM ING DATA')
          let id = val.split('plid___')[1]
          this.placeSelected = this.dataSource.find(x => x.place_id === id).main_text
        } else {
          this.placeSelected = val
        }
        if (val.length > 3){
          this.processingGoogle = true
          console.log('ALKJSDFLKJSDF', val)
          // /amenities?input=boston pizzza&lat=43.8085448&lng=-79.4844136
          let {lat,lng} = this.appData
          this.$api.get(`/amenities/?input=${val}&lat=${lat}&lng=${lng}`).then( ({data}) => {
            console.log('RETURNING DATA FROM PLACES AUTO CPOMPLETE', data)
            this.dataSource = data
            if (this.$store.state.plotCategory !== '__dev' && this.$store.state.plotCategory !== '__pinnedMarkers') this.marker.categoryId = this.$store.state.plotCategory
            else this.marker.categoryId = null
          }).catch( err => {
            return null
          })

        } else {
          this.processingGoogle = false
          this.dataSource = []
        }
      },
      visible(val){
        console.log('CHECKING VISIBLE & MANUAL MARKER',val,this.newDialog === 'marker---manual')
        if (val){
          console.log('RUNNING VISIBLE TRUE => ')

          if (this.newDialog && this.newDialog.includes('marker')){
            this.marker = {
              name:'',
              categoryId:null,
              geometry:{
                location:{
                  lat:null,
                  lng:null
                }
			        },
              icon:markerIcon,
              image:''
            },
            this.placeSelected = '',
            this.dataSource = []
            if (this.$store.state.plotCategory !== '__dev' && this.$store.state.plotCategory !== '__pinnedMarkers') this.marker.categoryId = this.$store.state.plotCategory
            else this.marker.categoryId = null

            if (this.newDialog && this.newDialog === 'marker---manual' || this.newDialog === 'marker---google'){
               console.log('RUNNING VISIBLE MARKER => ')
              let {lat,lng} = this.appData

              let self = this
              console.log('ABOUT TO DO MAP')
              this.location.geometry.location.lat = lat
              this.location.geometry.location.lng = lng
              setTimeout(() => {
                self.map = new maptalks.Map('mini-map', {
                  center : [lng,lat],
                  zoom: 12,
                  dragPitch: true,
                  dragRotate: true,
                  minZoom: 12,
                  maxZoom: 16,
                  dragRotatePitch: true,
                  baseLayer: new maptalks.TileLayer('base', {
                      urlTemplate: `http://{s}.basemaps.cartocdn.com/${this.mapTheme.type}/{z}/{x}/{y}.png`,
                      subdomains: ['a', 'b', 'c', 'd'],
                      attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>',
                      cssFilter: `hue-rotate(${this.mapTheme.cssFilter}deg)`
                  })
                })
                self.putMarker(lat,lng)
              }, 100);
            }
          }
        }
        else {
          	this.placeChosen = false
          	this.location = {
              name:'',
              image:'',
              formatted_address:'',
              geometry:{
                location:{
                  lat:null,
                  lng:null
                }
              }
            }
          	this.loading = false
          	this.category.label = ''
          	this.category.icon = markerIcon
            this.chosenGoogleCat = null
            this.marker.icon = markerIcon
        }

      },

    },
    computed:{
    mapTheme(){
      return this.$store.state.appData.theme
    },

    amenityCats(){
      return this.$store.state.amenityCats.map(x => {
        x.options = Object.entries(x.options).map( ([label,value]) => ({label,value}))
        return x
      })
		},
        drawerTitle(){
		  if (this.newDialog == "marker---google") return this.newDialog && 'Amenity Marker'
		  else if (this.newDialog == "marker---manual") return this.newDialog && 'Highlight Marker'
		  else return this.newDialog && 'New Category'

        },
        instance(){
          return this.$store.state.instance
        },
        categories(){
          return [...Object.values(this.$store.state.amenityCategories)]
        },
        appData(){
          console.log('APP DATAAAAA', this.$store.state.appData)
          return this.$store.state.appData
        },
        autoCompletedText(){
          let result = 'No Data'
          if (this.processingGoogle) result = 'processing'
          else if (this.dataSource.length) 'Found ' + this.dataSource.length

        },
        getTitle(){
		  if (this.drawerTitle) return `Add ${this.drawerTitle}`
		  return ''

        },
        visible(){
          return Boolean(this.newDialog)
        },
        newDialog(){
           return this.$store.state.addAmenityDialog
        }
    },
    methods: {
      req:msg=>({required:true,message:msg}),
      removeImage(){
        console.log("REMOVING IMAGE")
        this.marker.image = ''
      },
      resetVariables(){
        this.placeChosen = false
            this.location = {
              name:'',
              image:'',
              formatted_address:'',
              geometry:{
                location:{
                  lat:null,
                  lng:null
                }
              }
            }
            this.loading = false
            this.category.label = ''
            this.category.icon = markerIcon
        this.chosenGoogleCat = null
        this.marker.icon = markerIcon
      },
      selectedImg(url){
        console.log('SELECTED IMAGE',url)
        this.category.icon = url.url
      },
      updateCatIcon(data){
        console.log('SOME DATAAA here ->', data)
        this.category.icon = data
      },
      updateMarkerIcon(data){
      this.marker.icon = data
      },
      updateMarkerImage(data){
        this.marker.image = data
      },
      updateLocationImage(data){
        this.location.image = data
      },
      selectedMarkerIcon(url){
        this.marker.icon = url.url
      },
      selectedMarkerImage(url){
        this.marker.image = url.url
      },
      updateMarker(){
        let {lat,lng} = this.marker.geometry.location
      },
      putMarker(latitude,longitude){

        let self = this
        if (this.vectorLayer) this.map.removeLayer(this.vectorLayer);

        this.vectorLayer = new maptalks.VectorLayer('vector',{
          enableAltitude : true,        // enable altitude
          altitudeProperty : 'altitude' // altitude property in properties, default by 'altitude'
        }).addTo(this.map);

        this.point = new maptalks.Marker(
            [longitude, latitude],
            {
              draggable : true,
              properties : {
                altitude : 5
              }
            }
        );



        this.point.addTo(this.vectorLayer);

        this.point.setInfoWindow({
          //'autoOpenOn' : 'click',  //set to null if not to open window when clicking on map
          'single' : false,
          'width'  : 183,
          'height' : 105,
          'custom' : true,
          'dx' : -3,
          'dy' : -12,
          'content'   : '<div class="pop__content">' +
            '<div class="pop__title">Lat/Lng Info </div>' +
            `<div class="pop__time popup-lat-lng"> <span id="popup-lat">${latitude}</span> <br/><span id="popup-lng">${longitude}</span></div>` +
            '</div>'
        });
        this.marker.geometry.location = {
          lat:latitude,
          lng:longitude
        }

        this.point.openInfoWindow();


        // this.point.on('mouseenter', function (e) {
        //   console.log('e',e)
        // })
        this.point.on('dragend', function (e) {
          console.log('e',e)
          self.placeSelected = ''
          console.log('new coordiante', e.coordinate.y)
          if (document.querySelector('.popup-lat-lng')) {
            // document.getElementById("popup-lat").innerHTML=self.amenityOBJ.lat
            // document.getElementById("popup-lng").innerHTML=self.amenityOBJ.lng
            document.getElementById("popup-lat").innerHTML= e.coordinate.y
            document.getElementById("popup-lng").innerHTML= e.coordinate.x
            self.marker.geometry.location = {
              lat:e.coordinate.y,
              lng:e.coordinate.x
            }
            self.location.geometry.location = {
              lat:e.coordinate.y,
              lng:e.coordinate.x
            }
          }
        })

        self.map.animateTo({
            center: [longitude, latitude],
            zoom: 13,
            pitch: 40,
            bearing: 0
        }, {
            duration: 700
        })
      },
      googleCatChange(e){
        console.log('EEEEEEEEEEEEEE', e)
		let cat = this.amenityCats.find(x => x.label === e)
		this.chosenGCat = JSON.parse(JSON.stringify(cat))
		console.log("CHOSENGCAT", this.chosenGCat)
	  },
	  selectedCategories(e){
		  console.log('SELECTED FIELDS',e)
		  let cat = {
			  value: e
		  }
		  this.chosenGCat = JSON.parse(JSON.stringify(cat))
		  console.log("CHOSENGCAT",this.chosenGCat)

	  },
      selectIcon(){
        this.$store.commit('MEDIA_SELECT',{callback:this.selectedIcon,type:'images'})
      },
      iconLibrary(){
        this.$store.commit('LIBRARY_SELECT',{callback:this.selectedIcon,type:'icons'})
      },
      selectedIcon(item){
        if (typeof item === 'string') item = {url:item}
        this.category.icon = item.url
        console.log('URLLLLL',item)
      },
      submitNew(){


        if (this.newDialog && this.newDialog.includes('marker')){
          // let self = this
          // this.loading = true

          if (this.newDialog && this.newDialog.includes('google')){
            this.$refs.location.validate(valid => {
              if (valid) {
                let self = this
                this.loading = true
                console.log('AMENITY CATSSS', this.marker.categoryId)
                console.log('THIS LOCATION', this.location)
                this.$api.post(`/amenities/${this.instance.id}/${this.appData.id}/${this.marker.categoryId}`, this.location).then( ({data}) => {
                  this.loading = false
                  self.onClose({type:'new',data})
                  console.log('REGULAR MARKER DATA', data)
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						self.$message.error(self.$err(err))
					}
				})
              } else {
                console.log('error submit!!');
                return false;
              }
            });
            // if (!this.location.place_id || !this.marker.categoryId) {
            //   this.loading = false
            //   return this.$message.error('Something went wrong')
            // }
            // console.log('AMENITY CATSSS', this.marker.categoryId)
            // this.$api.post(`/amenities/${this.instance.id}/${this.appData.id}/${this.marker.categoryId}`, this.location).then( ({data}) => {
            //   self.onClose({type:'new',data})
            //   console.log('REGULAR MARKER DATA', data)
            // })

          } else {
            this.$refs.marker.validate(valid => {
              if (valid) {
                let self = this
                this.loading = true
                // if (!this.marker.geometry.location.lat || !this.marker.geometry.location.lng){
                //   this.loading = false
                //   return this.$message.error('Please enter the Address')
                // }
                this.marker.categoryId = '__pinnedMarkers'
                console.log('THIS MARKER', this.marker)
                this.$api.post(`/amenities/${this.instance.id}/${this.appData.id}/${this.marker.categoryId}`, this.marker).then( ({data}) => {
                  this.loading = false
                  //   data.markerOBJ.markerFile = this.marker.icon
                  console.log('PINNED MARKER DATA', data)
                  self.onClose({type:'new',data})
                }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						self.$message.error(self.$err(err))
					}
				})
              } else {
                console.log('error submit!!');
                return false;
              }
            });
            // if (!this.marker.name || !this.marker.geometry.location.lat || !this.marker.geometry.location.lng){
            //   this.loading = false
            //   return this.$message.error('Fields Missing')
			      // }
			      // this.marker.categoryId = '__pinnedMarkers'
            // this.$api.post(`/amenities/${this.instance.id}/${this.appData.id}/${this.marker.categoryId}`, this.marker).then( ({data}) => {
            //   //   data.markerOBJ.markerFile = this.marker.icon
            //   console.log('PINNED MARKER DATA', data)
            //   self.onClose({type:'new',data})
            // })
          }
        } else {
          console.log('NEW CATEGORY SUBMIT RAN')
          this.$refs.category.validate(valid => {
            if (valid) {
              let self = this
              this.loading = true
              let data = this.category
              console.log('DATA 1', data)
              data = this.chosenGCat
              console.log('DATA 2', data)
              data.label = this.category.label
              data.description = this.category.description
              data.icon = this.category.icon
              console.log('DATA 3', data)

              // if (!data || (data && !data.label) || (data && !data.description)) {
              //   this.loading = false
              //   return this.$message.error('Fields Missing')
              // }
              this.$api.post(`/amenities/${this.instance.id}/${this.appData.id}/`, data).then( ({data}) => {
                console.log('DATAAAAA FROM NEW CATEGORY', data)
                self.onClose({type:'new',data})
              }).catch(err => {
					if (!err || !err.response || !err.response.status || err.response.status !== 400) {
						self.$message.error(self.$err(err))
					}
				})
            } else {
              console.log('error submit!!');
              return false;
            }
          })

        }
      },
      resetPlaceSearch(){
        this.location = {}
        this.placeChosen = false
      },
      selected(e){
        console.log("EEEEEE",e)

		let found = this.dataSource.find(x => x.place_id === e)
		this.placeSelected = found.main_text + ', ' + found.secondary_text
        this.dataSource = []
        console.log('FOUND DATASOURCE', found)

        this.placeChosen = true
        this.processingGoogle = true
        this.location = {
          name:'',
          image:'',
          formatted_address:'',
          geometry:{
            location:{
              lat:null,
              lng:null
            }
          }
        }
        let self = this
        this.loadingPlace = true

        this.$api.get(`/amenities/${e}`).then( ({data}) => {
          console.log('APIIII DATAA LOCATION', data)
          self.loadingPlace = false
          self.location = data
          self.processingGoogle = false
          console.log('marker geo location', self.marker.geometry.location)
          self.marker.geometry.location = data.geometry.location
          self.putMarker(data.geometry.location.lat,data.geometry.location.lng)
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				self.$message.error(self.$err(err))
			}
		})
      },
      selectedManual(e){
        console.log("EEEEEE",e)

        var found = this.dataSource.find(x => x.place_id === e)
        this.placeSelected = found.main_text + ', ' + found.secondary_text
        this.dataSource = []
        console.log('FOUND DATASOURCE', found)
        this.placeChosen = true
        this.processingGoogle = true
        this.loadingPlace = true
        this.location = {
          name:'',
          image:'',
          formatted_address:'',
          geometry:{
            location:{
              lat:null,
              lng:null
            }
          }
        }
        let self = this
        this.$api.get(`/amenities/${e}`).then( ({data}) => {
          console.log('APIIII DATAA LOCATION', data)
          self.loadingPlace = false
		  self.marker.image = data.image
		  self.location = data
          self.processingGoogle = false
          console.log('marker geo location', self.marker.geometry.location)
          self.marker.geometry.location = data.geometry.location
          self.putMarker(data.geometry.location.lat,data.geometry.location.lng)
        }).catch(err => {
			if (!err || !err.response || !err.response.status || err.response.status !== 400) {
				self.$message.error(self.$err(err))
			}
		})
      },
      onClose({type='',data={}}) {

          this.$store.dispatch('closeAmenityDrawers',{type,data})
          if (this.$refs.category) {
            this.$refs.category.resetFields()
          }
          if (this.$refs.marker) {
            this.$refs.marker.resetFields()
          }
      },
    },
  };
</script>

<style>
  /* .ant-drawer-wrapper-body::-webkit-scrollbar{
    display: none;
  } */
  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group-title {
    color: #666;
    font-weight: bold;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item-group {
    border-bottom: 1px solid #f6f6f6;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item {
    padding-left: 16px;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu-item.show-all {
    text-align: center;
    cursor: default;
  }

  .certain-category-search-dropdown .ant-select-dropdown-menu {
    max-height: 300px;
  }
  .change-tooltip-color .ant-tooltip-content .ant-tooltip-inner{
      background:#3F3356;
  }
</style>
<style scoped>
  .certain-category-search-wrapper
    >>> .certain-category-search.ant-select-auto-complete
    .ant-input-affix-wrapper
    .ant-input-suffix {
    right: 12px;
  }
  .certain-category-search-wrapper >>> .certain-search-item-count {
    position: absolute;
    color: #999;
    right: 16px;
  }
  .certain-category-search-wrapper
    >>> .certain-category-search.ant-select-focused
    .certain-category-icon {
    color: #108ee9;
  }
  .certain-category-search-wrapper >>> .certain-category-icon {
    color: #6e6e6e;
    transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
    font-size: 16px;
  }
  .image-holder-box{
      display: table;
      width: 104px;
      height: 104px;
      text-align: center;
      vertical-align: top;
      background-color: #fafafa;
      border: 1px dashed #d9d9d9;
      border-radius: 4px;
      cursor: pointer;
  }
  .image-holder-box:hover{
      border-color: var(--orange);
      transition: border-color .3s ease;
  }
  .image-holder-box .content{
    display: table-cell;
    width: 100%;
    height: 100%;
    padding: 8px;
    text-align: center;
    vertical-align: middle;
  }
  .image-holder-box .content .inside{
    background-repeat: no-repeat;
    background-size: contain;
    background-position: 50%;
    width: 100px;
    height: 100px;
  }
  .image-selector {
	position: absolute;
	z-index: 1000;
	width: 250px;
	margin-top: 75px;
  }
</style>
